<style scoped lang="scss">
.rechner-section {
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: $width-sm) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.box-reset-btn {
  text-align: center;
  position: relative;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  .resetform-btn {
    border: 0;
    background: none;
    color: #ccc;
    background: #f1f1f1;
    font-size: 120%;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
      color: #212121;
      .b-icon {
        transform: rotate(-720deg);
        transition-duration: 1.5s;
        transition-delay: now;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }
  }
}
.rechner-box {
  position: relative;
  .rechner-box-main {
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background: #fff;
    padding: 15px 30px;
    position: relative;
    &:after {
      content: '';

      width: 105%;
      height: 107%;
      position: absolute;
      left: -2.5%;
      top: -3.5%;
      z-index: -1;
      @media (max-width: 992px) {
        display: none;
      }
    }
    @media (max-width: 710px) {
      box-shadow: none;
    }
    @media (max-width: $width-sm) {
      padding: 0;
    }
  }

  .row {
    align-items: center !important;
    justify-content: center !important;
    @media (max-width: $width-sm) {
    }
  }
  .header {
    padding: 10px;
    margin-bottom: 20px;

    .col-md-4 {
      padding-left: 0;
      @media (max-width: $width-sm) {
        padding-right: 15px;
      }
    }
    .col-md-8 {
      padding-right: 0;
      @media (max-width: $width-sm) {
        padding-right: 15px;
      }
    }
    img {
      max-width: 120px;
      @media (max-width: 576px) {
        margin: 0 auto;
        display: block;
        width: 100px;
      }
    }
    h1 {
      font-size: 160%;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      text-align: right;
      color: #000;
      font-family: $KleineSans-Bold;
      @media (max-width: 576px) {
        font-size: 130%;
        margin-top: 10px;
        text-align: center;
      }
      span {
        display: inline-block;
        font-family: $KleineSans-Bold;
        @media (max-width: 576px) {
          text-align: center;
        }
      }
      &:after {
        display: none;
      }
    }
    h2 {
      font-size: 120%;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      text-align: right;
      color: #000;
      border-left: 0 !important;
      @media (max-width: 576px) {
        text-align: center;
      }
      &:after {
        display: none;
      }
    }
  }
}

.auswahl-box,
.ausgabe-box,
.ausgabe-box-gesamt {
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: $width-sm) {
    text-align: center;
  }
  .align-right {
    text-align: right;
    @media (max-width: $width-sm) {
      text-align: center;
    }
  }
  input,
  .form-control {
    border-radius: 0 !important;
    @media (max-width: 576px) {
      margin-top: 5px;
    }
  }
  .custom-select {
    border-radius: 0;
    &:focus {
      border-color: #fff;
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.15);
    }
    option {
      padding: 5px 0 !important;
    }
    @media (max-width: 576px) {
      margin-top: 5px;
    }
  }
  .b-form-datepicker {
    border-radius: 0;
    @media (max-width: 576px) {
      margin-top: 5px;
    }
  }
  .datepicker {
    //border-radius: 0 !important;
  }

  .btn-group {
    .btn {
      border-radius: 0;
      font-size: 95%;
      margin-right: 10px;
      border: 0px solid #eee;
      background: #eee;
      color: #212121;
      &:hover {
        background: #f1f1f1;
      }
      &:focus {
        border: 0px solid #eee;
      }
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active {
      background-color: #212121;
      border-color: #212121;
      color: #fff;
      box-shadow: none;
    }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
      border-color: #212121;
    }
  }
}
.ausgabe-box {
  font-family: $KleineSans-Bold;
  span {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  @media (max-width: $width-sm) {
    text-align: center;
    .align-right {
      text-align: center;
    }
  }
}
.ausgabe-box-gesamt {
  font-family: $KleineSans-Bold;
  font-size: 120%;
  margin-bottom: 5px;
  padding: 10px 0;
  background: #f1f1f1;

  span {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.transition-produktImages-enter,
.transition-produktImages-leave-to {
  //transform:scale(0.1);
  opacity: 0;
  //margin-left: -33%;
  flex: 0 0 0;
  max-width: 0;
}
.transition-produktImages-enter-active,
.transition-produktImages-leave-active {
  transition: all 0.5s;
}

.foldup-enter-active,
.foldup-leave-active {
  transition: all 0.5s;
}

.foldup-enter, .foldup-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  margin-top: -58px;
}

.produkt-img-box {
  @media (max-width: 768px) {
    border-top: 1px solid #eee;
    margin-top: 30px;
    padding-top: 15px;
  }
  .image-box-inner {
    display: block;
    width: 100%;

    .titel-prod {
      text-align: center;
      margin-bottom: 15px;

      letter-spacing: 1px;
    }
    img {
      display: block;
      max-width: 80%;
      margin: 0 auto;
    }
  }
}

.rechner-content {
  z-index: 2;
  @media (max-width: $width-lg) {
    margin-bottom: 40px;
  }
}

.produkt-img-box {
  z-index: 2;
}
</style>

<template>
  <div class="rechner-section">
    <div class="box-reset-btn">
      <!--- <div @click="reloadPage" class="resetform-btn" v-b-tooltip.hover title="Formular zurücksetzen">
        <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
      </div> --->
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-8 justify-content-center align-items-center rechner-content">
        <div class="rechner-box">
          <div class="rechner-box-main">
            <div class="row header">
              <div class="col-sm-4 ">
                <img src="@/assets/klz-logo.svg" alt="Kleine Zeitung" />
              </div>
              <div class="col-sm-8 ">
                <!--<h1>Druckkosten- und <span>Beilagenrechner</span></h1>-->
                <h2>Tarif 2022</h2>
              </div>
            </div>
            <div class="row auswahl-box">
              <div class="col-sm-4 ">
                <span>Produkt</span>
              </div>
              <div class="align-right col-sm-8 ">
                <select class="custom-select" @change="onProduktChange" v-model="produkt">
                  <option :value="option.value" v-for="(option, index) in produktOptions" :key="index">{{ option.text }}</option>
                </select>
              </div>
            </div>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showCategory">
                <div class="col-sm-4 ">
                  <span>Kategorie</span>
                </div>
                <div class="align-right col-sm-8 ">
                  <select class="custom-select" @change="onKategorieChange" v-model="kategorie">
                    <option :value="option.value" v-for="(option, index) in kategorieOptions" :key="index">{{ option.text }}</option>
                  </select>
                </div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showSeiten">
                <div class="col-sm-4 ">
                  <span>Seiten</span>
                </div>
                <div class="align-right col-sm-8 ">
                  <select class="custom-select" v-model="seite">
                    <option :value="option.value" v-for="(option, index) in seitenOptions" :key="index">{{ option.text }}</option>
                  </select>
                </div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showMenge">
                <div class="col-sm-4 ">
                  <span>Menge</span>
                </div>
                <div class="align-right col-sm-8 ">
                  <input type="number" @change="onMengeChange" v-model="menge" class="form-control" />
                </div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="seite">
                <div class="col-sm-6">
                  <span>Gewicht der Beilage</span>
                </div>
                <div class="align-right col-sm-6">{{ seite.gewicht }}g</div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row ausgabe-box" v-if="showDruckkosten">
                <div class="col-sm-6">
                  <span>Druckkosten</span>
                </div>
                <div class="align-right col-sm-6">
                  {{ formatprice(druckkosten) }}
                </div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showBundesland">
                <div class=" col-sm-4 col-xs-12">
                  <span>Platzierung</span>
                </div>
                <div class="align-right col-sm-8 col-xs-12">
                  <div role="radiogroup" class="btn-group-toggle btn-group bv-no-focus-ring">
                    <label :class="['btn', 'btn-outline-primary', { active: bundesland == 'kombi' }]" data-children-count="1"> <input type="radio" value="kombi" name="bundesland" v-model="bundesland" />Kombination </label>
                    <label :class="['btn', 'btn-outline-primary', { active: bundesland == 'steiermark' }]" data-children-count="1"> <input type="radio" value="steiermark" name="bundesland" v-model="bundesland" />Steiermark </label>
                    <label :class="['btn', 'btn-outline-primary', { active: bundesland == 'kaernten' }]" data-children-count="1"> <input type="radio" value="kaernten" name="bundesland" v-model="bundesland" />Kärnten</label>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showUmfang">
                <div class="col-sm-6">
                  <span>Umfang</span>
                </div>
                <div class="align-right col-sm-6">
                  <div role="radiogroup" class="btn-group-toggle btn-group bv-no-focus-ring">
                    <label :class="['btn', 'btn-outline-primary', { active: umfang == 'zweiseitig' }]" data-children-count="1"> <input type="radio" value="zweiseitig" name="umfang" v-model="umfang" />2-seitig </label>
                    <label :class="['btn', 'btn-outline-primary', { active: umfang == 'vierseitig' }]" data-children-count="1"> <input type="radio" value="vierseitig" name="umfang" v-model="umfang" />4-seitig </label>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showPerforation">
                <div class="col-sm-4">
                  <span>Perforation</span>
                </div>
                <div class="col-sm-8">
                  <div role="radiogroup" class="btn-group-toggle btn-group bv-no-focus-ring">
                    <label :class="['btn', 'btn-outline-primary', perforation == 'keine' ? 'active' : '']" data-children-count="1"> <input type="radio" value="keine" name="perforation" v-model="perforation" />keine </label>
                    <label :class="['btn', 'btn-outline-primary', perforation == '1x' ? 'active' : '']" data-children-count="1"> <input type="radio" value="1x" name="perforation" v-model="perforation" />1-fach </label>
                    <label :class="['btn', 'btn-outline-primary', perforation == '2x' ? 'active' : '']" data-children-count="1"> <input type="radio" value="2x" name="perforation" v-model="perforation" />2-fach </label>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showPlatzierung">
                <div class="col-sm-4">
                  <span>Platzierung</span>
                </div>
                <div class="col-sm-8">
                  <div role="radiogroup" class="btn-group-toggle btn-group bv-no-focus-ring">
                    <label :class="['btn', 'btn-outline-primary', platzierung == 'titelseite' ? 'active' : '']" data-children-count="1"> <input type="radio" value="titelseite" name="platzierung" v-model="platzierung" />Titelseite </label>
                    <label :class="['btn', 'btn-outline-primary', platzierung == 'u4' ? 'active' : '']" data-children-count="1"> <input type="radio" value="u4" name="platzierung" v-model="platzierung" />U4 </label>
                  </div>
                </div>
              </div>
            </transition>
            <div class="row auswahl-box">
              <div class="col-sm-4  ">
                <span>Erscheinungstag</span>
              </div>
              <div class="align-right col-sm-8">
                <DateTimePicker v-model="date" placeholder="Bitte wählen Sie ein Datum"></DateTimePicker>
              </div>
            </div>
            <transition name="foldup">
              <div class="row ausgabe-box" v-if="showVerarbeitungskosten">
                <div class="col-sm-6">
                  <span>Verarbeitungskosten</span>
                </div>
                <div class="align-right col-sm-6 ">
                  {{ formatprice(verarbeitungskosten) }}
                </div>
              </div>
            </transition>
            <!-- Zwischenssume -->
            <transition name="foldup">
              <div class="row ausgabe-box" v-if="showVerarbeitungskosten">
                <div class="col-sm-6">
                  <span>Zwischensumme </span>
                </div>
                <div class="align-right col-sm-6">
                  {{ formatprice(zwischensumme) }}
                </div>
              </div>
            </transition>
            <!-- -->
            <transition name="foldup">
              <div class="row auswahl-box" v-if="showVerarbeitungskosten">
                <div class="col-sm-6">
                  <span>Werbeabgabe <strong>5%</strong></span>
                </div>
                <div class="align-right col-sm-6">
                  {{ formatprice(werbeabgabe) }}
                </div>
              </div>
            </transition>
            <div class="row auswahl-box">
              <div class="col-sm-6">
                <span>UST <strong>20%</strong></span>
              </div>
              <div class="align-right col-sm-6">
                {{ formatprice(ust) }}
              </div>
            </div>
            <div class="row ausgabe-box-gesamt">
              <div class="col-sm-6">
                <span>Gesamtkosten</span>
              </div>
              <div class="align-right col-sm-6">
                {{ formatprice(gesamtkosten) }}
                <!-- <hr />
                {{ kategorie.verarbeitung }}

                <hr />
                {{ verarbeitung }} -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <transition name="transition-produktImages">
        <div v-if="showPicture" class="col-lg-4 produkt-img-box">
          <div class="image-box-inner">
            <div class="titel-prod">{{ kategoriename }}</div>
            <img v-if="showPicture" :src="picture" alt="Tip on Card" class="img-fluid" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import DateTimePicker from '@/components/util/DateTimePicker.vue';

export default defineComponent({
  name: 'Tarifrechner',
  components: {
    DateTimePicker,
  },
  data() {
    return {
      produkt: false,
      kategorie: false,
      seite: false,
      menge: 5000,
      date: null,
      perforation: 'keine',
      platzierung: 'titelseite',
      bundesland: 'kombi',
      umfang: 'zweiseitig',
    };
  },
  props: {
    tarifData: Object,
  },
  methods: {
    formatprice(value) {
      return `€ ${parseFloat(value)
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
    },
    onMengeChange() {
      if (this.menge <= 5000) {
        this.menge = 5000;
      }
    },
    onProduktChange() {
      this.kategorie = false;
      this.seite = false;

      if (Object.keys(this.produkt)[0] == 'none') {
        this.kategorie = this.produkt.none;
      }
    },
    onKategorieChange() {
      this.seite = false;
    },
    reloadPage() {
      this.produkt = false;
      this.kategorie = false;
      this.seite = false;
      this.menge = 5000;
      this.date = false;
      this.perforation = 'keine';
      this.platzierung = 'titelseite';
      this.bundesland = 'kombi';
      this.umfang = 'zweiseitig';
    },
  },
  computed: {
    showCategory() {
      return this.produkt && !(Object.keys(this.produkt)[0] == 'none');
    },
    showSeiten() {
      return this.produkt && this.kategorie && this.kategorie.type == 'paged';
    },
    showPerforation() {
      return this.kategorie && this.kategorie.verarbeitung == 'tipon';
    },
    showPlatzierung() {
      return this.kategorie && this.kategorie.verarbeitung == 'tipon';
    },
    showPicture() {
      return (this.kategorie && this.kategorie.bild) || (this.produkt && this.produkt[Object.keys(this.produkt)[0]] && this.produkt[Object.keys(this.produkt)[0]].bild);
    },
    showVerarbeitungskosten() {
      return this.kategorie && this.kategorie.verarbeitung != 'none';
    },
    showDruckkosten() {
      return this.kategorie && this.kategorie.type != 'noprint';
    },
    showBundesland() {
      return this.kategorie && this.kategorie.verarbeitung == 'bundesland';
    },
    showUmfang() {
      return this.showBundesland;
    },
    showMenge() {
      return this.kategorie && this.kategorie.verarbeitung != 'bundesland';
    },
    isWeekday() {
      return ![0, 6].includes(this.day);
    },
    kategoriename() {
      if (this.kategorie && this.kategorie.kategoriename) {
        return this.kategorie.kategoriename;
      }

      if (this.produkt && this.produkt[Object.keys(this.produkt)[0]] && this.produkt[Object.keys(this.produkt)[0]].kategoriename) {
        return this.produkt[Object.keys(this.produkt)[0]].kategoriename;
      }
      return '';
    },
    picture() {
      if (this.kategorie && this.kategorie.bild) {
        return `img/img-prod/${this.kategorie.bild}`;
      }
      if (this.produkt && this.produkt[Object.keys(this.produkt)[0]] && this.produkt[Object.keys(this.produkt)[0]].bild) {
        return `img/img-prod/${this.produkt[Object.keys(this.produkt)[0]].bild}`;
      }
      return '';
    },
    day() {
      return this.date ? new Date(this.date).getDay() : 1;
    },
    verarbeitung() {
      return this.tarifData.verarbeitung;
    },
    produktOptions() {
      return [
        { text: '-- Bitte wählen --', value: false },
        ...Object.keys(this.tarifData.produkte).map(
          function(key) {
            return { text: key, value: this.tarifData.produkte[key] };
          }.bind(this)
        ),
      ]; //this.tarifData.produkte
    },
    kategorieOptions() {
      return [
        { text: '-- Bitte wählen --', value: false },
        ...Object.keys(this.produkt).map(
          function(key) {
            return { text: key, value: this.produkt[key] };
          }.bind(this)
        ),
      ]; //this.tarifData.produkte
    },
    seitenOptions() {
      if (!this.kategorie) return {};
      if (this.kategorie.type == 'paged') {
        return [
          { text: '-- Bitte wählen --', value: false },
          ...this.kategorie.items.map(function(item) {
            return { text: item.seiten, value: item };
          }),
        ];
      }
      return {};
    },
    bundeslandOptions() {
      return [
        { text: 'Kombination', value: 'kombi' },
        { text: 'Steiermark', value: 'kombi' },
        { text: 'Kärnten', value: 'kombi' },
      ];
    },
    druckkosten() {
      if (!this.produkt && !this.kategorie) return 0;
      if (this.kategorie.type == 'paged' && this.seite) {
        return (this.seite.grundpreis + (this.menge - 5000) * (this.seite.weiteretausend / 1000)).toFixed(2);
      } else if (this.kategorie.type == 'single') {
        let base = 0;
        let priceper1k = 0;
        let count = 0;
        this.kategorie.items.forEach(
          function(item) {
            if (item.menge <= this.menge) {
              base = item.grundpreis;
              priceper1k = item.weiteretausend / 1000;
              count = this.menge - item.menge;
            }
          }.bind(this)
        );
        return base + count * priceper1k;
      }

      return 0;
    },
    verarbeitungskosten() {
      if (!this.produkt && this.kategorie) return 0;

      let price = 0;
      let fixed = 0;
      let gewicht = 0;
      const verarbeitung = this.verarbeitung[this.kategorie.verarbeitung];
      let tarife = [];

      switch (this.kategorie.verarbeitung) {
        case 'beilagen':
        case 'mantel':
          console.log(verarbeitung);
          tarife = this.isWeekday ? verarbeitung.wochentag : verarbeitung.sonntag;
          gewicht = this.seite.gewicht || 11; // 11 = MANTEL LWC
          tarife.forEach(
            function(item) {
              if (gewicht >= item.grammatur) {
                price = this.menge >= 80000 ? item.preisab80k : item.preisunter80k;
              }
            }.bind(this)
          );
          return (price / 1000) * this.menge;
        case 'folder':
        case 'flyer':
          tarife = this.isWeekday ? verarbeitung['wochentag'] : verarbeitung['sonntag'];
          gewicht = 20; // 20g = Folder, Flyer
          tarife.forEach(
            function(item) {
              if (gewicht >= item.grammatur) {
                price = this.menge >= 80000 ? item.preisab80k : item.preisunter80k;
              }
            }.bind(this)
          );
          console.log(verarbeitung, tarife, price);
          return (price / 1000) * this.menge;
        case 'tipon':
          tarife = this.isWeekday ? verarbeitung[this.platzierung]['wochentag'] : verarbeitung[this.platzierung]['sonntag'];
          fixed = verarbeitung.perforation[this.perforation];
          price = this.menge >= 80000 ? tarife.preisab80k : tarife.preisunter80k;
          return (price / 1000) * this.menge + fixed;
        case 'bundesland':
          tarife = this.isWeekday ? verarbeitung[this.umfang][this.bundesland]['wochentag'] : verarbeitung[this.umfang][this.bundesland]['sonntag'];
          return tarife;
      }

      return price;
    },
    werbeabgabe() {
      return this.verarbeitungskosten * 0.05;
    },
    zwischensumme() {
      return parseFloat(this.druckkosten) + parseFloat(this.verarbeitungskosten);
    },
    ust() {
      return (parseFloat(this.druckkosten) + parseFloat(this.verarbeitungskosten) + parseFloat(this.werbeabgabe)) * 0.2;
    },
    gesamtkosten() {
      return parseFloat(this.druckkosten) + parseFloat(this.verarbeitungskosten) + parseFloat(this.werbeabgabe) + parseFloat(this.ust);
    },
  },
});
</script>
